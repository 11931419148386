import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './OverviewEmployeeList.css';

const OverviewEmployeeList = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    DateOfBirth: '',
    Gender: 'Other',
    HireDate: '',
    JobTitle: '',
    PaymentType: 'Salary',
    Salary: '',
    HourlyRate: '',
    Address: '',
    City: '',
    Country: '',
    CompanyId: '',
  });

  const employees = [
    {
      EmployeeId: 1,
      FirstName: 'John',
      LastName: 'Doe',
      Email: 'john.doe@example.com',
      PhoneNumber: '123-456-7890',
      DateOfBirth: '1985-05-15',
      Gender: 'Male',
      HireDate: '2010-01-10',
      JobTitle: 'Software Engineer',
      PaymentType: 'Salary',
      Salary: 80000,
      Status: 'Active',
      Address: '123 Main St',
      City: 'New York',
      Country: 'USA',
      CompanyId: 1,
    },
    {
      EmployeeId: 2,
      FirstName: 'Jane',
      LastName: 'Smith',
      Email: 'jane.smith@example.com',
      PhoneNumber: '234-567-8901',
      DateOfBirth: '1990-03-22',
      Gender: 'Female',
      HireDate: '2015-06-01',
      JobTitle: 'Project Manager',
      PaymentType: 'Hourly',
      HourlyRate: 50,
      Status: 'On Leave',
      Address: '456 Elm St',
      City: 'Los Angeles',
      Country: 'USA',
      CompanyId: 2,
    },
    {
      EmployeeId: 3,
      FirstName: 'Alice',
      LastName: 'Johnson',
      Email: 'alice.johnson@example.com',
      PhoneNumber: '345-678-9012',
      DateOfBirth: '1982-11-30',
      Gender: 'Female',
      HireDate: '2012-03-15',
      JobTitle: 'Designer',
      PaymentType: 'Salary',
      Salary: 70000,
      Status: 'Active',
      Address: '789 Pine St',
      City: 'Chicago',
      Country: 'USA',
      CompanyId: 1,
    },
  ];

  const handleEdit = (event, id) => {
    event.stopPropagation();
    const employee = employees.find(emp => emp.EmployeeId === id);
    if (employee) {
      setEmployeeData({
        ...employee,
        DateOfBirth: new Date(employee.DateOfBirth).toISOString().split('T')[0], // Format DateOfBirth
        HireDate: new Date(employee.HireDate).toISOString().split('T')[0], // Format HireDate
      });
      setModalOpen(true);
    }
  };

  const handleAdd = () => {
    setEmployeeData({
      FirstName: '',
      LastName: '',
      Email: '',
      PhoneNumber: '',
      DateOfBirth: '',
      Gender: 'Other',
      HireDate: '',
      JobTitle: '',
      PaymentType: 'Salary',
      Salary: '',
      HourlyRate: '',
      Address: '',
      City: '',
      Country: '',
      CompanyId: '',
    });
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to edit this employee?")) {
      console.log('Employee Data Submitted:', employeeData);
      setModalOpen(false);
    }
  };

  const handleNavigateToEmployeeDetails = (id) => {
    navigate(`/dashboard/employeedetail/${id}`);
  };

  return (
    <div className="overview-employee-list">
      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <span className="close-x" onClick={handleModalClose}>
              <img src="/icons/x-icon.svg" alt="Close" />
            </span>
            <h2>{employeeData.EmployeeId ? 'Edit Employee' : 'Add Employee'}</h2>
            <form onSubmit={handleSubmit}>
              <h3>Personal Information</h3>
              <div className="label-and-input-pair">
                <label>First Name:</label>
                <input
                  type="text"
                  value={employeeData.FirstName}
                  onChange={(e) => setEmployeeData({ ...employeeData, FirstName: e.target.value })}
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Last Name:</label>
                <input
                  type="text"
                  value={employeeData.LastName}
                  onChange={(e) => setEmployeeData({ ...employeeData, LastName: e.target.value })}
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Email:</label>
                <input
                  type="email"
                  value={employeeData.Email}
                  onChange={(e) => setEmployeeData({ ...employeeData, Email: e.target.value })}
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Phone Number:</label>
                <input
                  type="text"
                  value={employeeData.PhoneNumber}
                  onChange={(e) => setEmployeeData({ ...employeeData, PhoneNumber: e.target.value })}
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Date of Birth:</label>
                <input
                  type="date"
                  value={employeeData.DateOfBirth}
                  onChange={(e) => setEmployeeData({ ...employeeData, DateOfBirth: e.target.value })}
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Gender:</label>
                <select
                  value={employeeData.Gender}
                  onChange={(e) => setEmployeeData({ ...employeeData, Gender: e.target.value })}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="label-and-input-pair">
                <label>Address:</label>
                <input
                  type="text"
                  value={employeeData.Address}
                  onChange={(e) => setEmployeeData({ ...employeeData, Address: e.target.value })}
                />
              </div>
              <div className="label-and-input-pair">
                <label>City:</label>
                <input
                  type="text"
                  value={employeeData.City}
                  onChange={(e) => setEmployeeData({ ...employeeData, City: e.target.value })}
                />
              </div>
              <div className="label-and-input-pair">
                <label>Country:</label>
                <input
                  type="text"
                  value={employeeData.Country}
                  onChange={(e) => setEmployeeData({ ...employeeData, Country: e.target.value })}
                />
              </div>
              <br></br>
              <h3>Employment Information</h3>
              <div className="label-and-input-pair">
                <label>Hire Date:</label>
                <input
                  type="date"
                  value={employeeData.HireDate}
                  onChange={(e) => setEmployeeData({ ...employeeData, HireDate: e.target.value })}
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Job Title:</label>
                <input
                  type="text"
                  value={employeeData.JobTitle}
                  onChange={(e) => setEmployeeData({ ...employeeData, JobTitle: e.target.value })}
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Payment Type:</label>
                <select
                  value={employeeData.PaymentType}
                  onChange={(e) => setEmployeeData({ ...employeeData, PaymentType: e.target.value })}
                >
                  <option value="Salary">Salary</option>
                  <option value="Hourly">Hourly</option>
                </select>
              </div>
              <div className="label-and-input-pair">
                {employeeData.PaymentType === 'Salary' ? (
                  <>
                    <label>Salary:</label>
                    <input
                      type="number"
                      value={employeeData.Salary}
                      onChange={(e) => setEmployeeData({ ...employeeData, Salary: e.target.value })}
                      required
                    />
                  </>
                ) : (
                  <>
                    <label>Hourly Rate:</label>
                    <input
                      type="number"
                      value={employeeData.HourlyRate}
                      onChange={(e) => setEmployeeData({ ...employeeData, HourlyRate: e.target.value })}
                      required
                    />
                  </>
                )}
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
      <h1>Employee Overview</h1>
      <button className="add-button" onClick={handleAdd}>Add Employee</button>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>DOB</th>
              <th>Gender</th>
              <th>Hire Date</th>
              <th>Job Title</th>
              <th>Payment Type</th>
              <th>Status</th>
              <th>Salary/Rate</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {employees.map(employee => (
              <tr key={employee.EmployeeId} onClick={() => handleNavigateToEmployeeDetails(employee.EmployeeId)}>
                <td>{employee.FirstName} {employee.LastName}</td>
                <td>{employee.Email}</td>
                <td>{employee.PhoneNumber}</td>
                <td>{new Date(employee.DateOfBirth).toLocaleDateString()}</td>
                <td>{employee.Gender}</td>
                <td>{new Date(employee.HireDate).toLocaleDateString()}</td>
                <td>{employee.JobTitle}</td>
                <td>{employee.PaymentType}</td>
                <td>{employee.Status}</td>
                <td>{employee.PaymentType === 'Salary' ? `$${employee.Salary}` : `$${employee.HourlyRate}/hr`}</td>
                <td>
                  <div className="button-group">
                    <button className="edit-button" onClick={(e) => handleEdit(e, employee.EmployeeId)}>Edit</button>
                    <button className="view-details-button" onClick={() => handleNavigateToEmployeeDetails(employee.EmployeeId)}>View Details</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OverviewEmployeeList;
