//import { useParams } from 'react-router-dom';
import DashboardNavbar from '../../components/DashboardNavbar/DashboardNavbar';
import EmployeeDetailInfoCard from '../../components/EmployeeDetailInfoCard/EmployeeDetailInfoCard';
import EmployeeDetailHoursWorkedChart from '../../components/EmoloyeeDetailHoursWorkedChart/EmoloyeeDetailsHoursWorkedChart';
import EmployeeDetailUsedLeaveCircle from '../../components/EmployeeDetailUsedLeaveCircle/EmployeeDetailUsedLeaveCircle';
import EmployeeDetailEarnings from '../../components/EmployeeDetailEarnings/EmployeeDetailEarnings';
import './EmployeeDetail.css';

const EmployeeDetail = () => {
    //const { employeeId } = useParams();
    
    const employee = {
        EmployeeId: 1,
        FirstName: 'John',
        LastName: 'Doe',
        Email: 'john.doe@example.com',
        PhoneNumber: '123-456-7890',
        DateOfBirth: '1985-05-15',
        Gender: 'Male',
        HireDate: '2010-01-10',
        JobTitle: 'Software Engineer',
        PaymentType: 'Salary',
        Salary: 80000,
        Status: 'Active',
        Address: '123 Main St',
        City: 'New York',
        Country: 'USA',
        CompanyId: 1,
    };

    const handleEdit = () => {
        // Logic for editing employee details
        console.log("Edit Employee:", employee.EmployeeId);
    };

    const handleDelete = () => {
        // Logic for deleting employee
        console.log("Delete Employee:", employee.EmployeeId);
    };

    return (
        <div className="employee-detail">
            <DashboardNavbar />
            <div className='content'>
                <EmployeeDetailInfoCard 
                    employee={employee} 
                    onEdit={handleEdit} 
                    onDelete={handleDelete} 
                />
                <EmployeeDetailHoursWorkedChart />
                <EmployeeDetailUsedLeaveCircle />
                <EmployeeDetailEarnings />
            </div>
        </div>
    );
};

export default EmployeeDetail;
