import React, { useState } from 'react';
import './EmployeeDetailEarnings.css';

const EmployeeDetailEarnings = () => {
    const [timeframe, setTimeframe] = useState('weekly');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [customDate, setCustomDate] = useState('');

    const handleTimeframeChange = (e) => {
        setTimeframe(e.target.value);
        if (e.target.value !== 'customRange' && e.target.value !== 'customSingle') {
            setStartDate('');
            setEndDate('');
            setCustomDate('');
        }
    };

    const renderEarnings = () => {
        switch (timeframe) {
            case 'daily':
                return '$200'; // Replace with actual logic
            case 'weekly':
                return '$1,000'; // Replace with actual logic
            case 'monthly':
                return '$4,000'; // Replace with actual logic
            case 'customRange':
                return `3000$`; // Replace with actual logic
            case 'customSingle':
                return `3000$`; // Replace with actual logic
            default:
                return '$0';
        }
    };

    return (
        <div className="employee-detail-earnings">
            <div className="card">
                <h2 className="header">Employee Earnings</h2>

                <div className="timeframe-selector">
                    {['daily', 'weekly', 'monthly', 'customRange', 'customSingle'].map((value) => (
                        <label key={value}>
                            <input
                                type="radio"
                                value={value}
                                checked={timeframe === value}
                                onChange={handleTimeframeChange}
                            />
                            {value === 'customRange' ? 'Custom Range' : value.charAt(0).toUpperCase() + value.slice(1).replace('Single', ' Day')}
                        </label>
                    ))}
                </div>

                {timeframe === 'customRange' && (
                    <div className="custom-date-picker">
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="date-input"
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="date-input"
                        />
                    </div>
                )}

                {timeframe === 'customSingle' && (
                    <div className="custom-single-date-picker">
                        <input
                            type="date"
                            value={customDate}
                            onChange={(e) => setCustomDate(e.target.value)}
                            className="date-input"
                        />
                    </div>
                )}

                <div className="earnings-display">
                    <h3>{renderEarnings()}</h3>
                </div>
            </div>
        </div>
    );
};

export default EmployeeDetailEarnings;
