import React, { useState } from 'react';
import './EmployeeDetailInfoCard.css';

const EmployeeDetailInfoCard = ({ employee, onEdit, onDelete }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [employeeData, setEmployeeData] = useState({
        FirstName: employee.FirstName,
        LastName: employee.LastName,
        Email: employee.Email,
        PhoneNumber: employee.PhoneNumber,
        DateOfBirth: employee.DateOfBirth,
        Gender: employee.Gender,
        HireDate: employee.HireDate,
        JobTitle: employee.JobTitle,
        PaymentType: employee.PaymentType,
        Salary: employee.Salary,
        Status: employee.Status,
        Address: employee.Address,
        City: employee.City,
        Country: employee.Country,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        onEdit(employeeData); // Pass updated data to parent
        if (window.confirm("Are you sure you want to edit this employee?")) {
            console.log('Employee Data Submitted:', employeeData);
            setModalOpen(false);
        }
    };

    return (
        <div className="employee-detail-info-card">
            <div className="employee-card">
                <h2>{`${employee.FirstName} ${employee.LastName}`}</h2>
                <div className="employee-info">
                    <p><strong>Employee ID:</strong> {employee.EmployeeId}</p>
                    <p><strong>Email:</strong> {employee.Email}</p>
                    <p><strong>Phone:</strong> {employee.PhoneNumber}</p>
                    <p><strong>Date of Birth:</strong> {employee.DateOfBirth}</p>
                    <p><strong>Gender:</strong> {employee.Gender}</p>
                    <p><strong>Hire Date:</strong> {employee.HireDate}</p>
                    <p><strong>Job Title:</strong> {employee.JobTitle}</p>
                    <p><strong>Payment Type:</strong> {employee.PaymentType}</p>
                    <p><strong>Salary:</strong> ${employee.Salary}</p>
                    <p><strong>Status:</strong> {employee.Status}</p>
                    <p><strong>Address:</strong> {employee.Address}, {employee.City}, {employee.Country}</p>
                </div>
                <div className="employee-actions">
                    <button className="edit-button" onClick={() => setModalOpen(true)}>Edit</button>
                    <button className="delete-button" onClick={onDelete}>Delete</button>
                </div>
            </div>

            {modalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <span className="close-x" onClick={() => setModalOpen(false)}>
                            <img src="/icons/x-icon.svg" alt="Close" />
                        </span>
                        <h2>Edit Employee</h2>
                        <form onSubmit={handleSubmit}>
                            <h3>Personal Information</h3>
                            <div className="label-and-input-pair">
                                <label>First Name:</label>
                                <input
                                    type="text"
                                    value={employeeData.FirstName}
                                    onChange={(e) => setEmployeeData({ ...employeeData, FirstName: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="label-and-input-pair">
                                <label>Last Name:</label>
                                <input
                                    type="text"
                                    value={employeeData.LastName}
                                    onChange={(e) => setEmployeeData({ ...employeeData, LastName: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="label-and-input-pair">
                                <label>Email:</label>
                                <input
                                    type="email"
                                    value={employeeData.Email}
                                    onChange={(e) => setEmployeeData({ ...employeeData, Email: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="label-and-input-pair">
                                <label>Phone Number:</label>
                                <input
                                    type="text"
                                    value={employeeData.PhoneNumber}
                                    onChange={(e) => setEmployeeData({ ...employeeData, PhoneNumber: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="label-and-input-pair">
                                <label>Date of Birth:</label>
                                <input
                                    type="date"
                                    value={employeeData.DateOfBirth}
                                    onChange={(e) => setEmployeeData({ ...employeeData, DateOfBirth: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="label-and-input-pair">
                                <label>Gender:</label>
                                <select
                                    value={employeeData.Gender}
                                    onChange={(e) => setEmployeeData({ ...employeeData, Gender: e.target.value })}
                                >
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="label-and-input-pair">
                                <label>Address:</label>
                                <input
                                    type="text"
                                    value={employeeData.Address}
                                    onChange={(e) => setEmployeeData({ ...employeeData, Address: e.target.value })}
                                />
                            </div>
                            <div className="label-and-input-pair">
                                <label>City:</label>
                                <input
                                    type="text"
                                    value={employeeData.City}
                                    onChange={(e) => setEmployeeData({ ...employeeData, City: e.target.value })}
                                />
                            </div>
                            <div className="label-and-input-pair">
                                <label>Country:</label>
                                <input
                                    type="text"
                                    value={employeeData.Country}
                                    onChange={(e) => setEmployeeData({ ...employeeData, Country: e.target.value })}
                                />
                            </div>
                            <br></br>
                            <h3>Employment Information</h3>
                            <div className="label-and-input-pair">
                                <label>Hire Date:</label>
                                <input
                                    type="date"
                                    value={employeeData.HireDate}
                                    onChange={(e) => setEmployeeData({ ...employeeData, HireDate: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="label-and-input-pair">
                                <label>Job Title:</label>
                                <input
                                    type="text"
                                    value={employeeData.JobTitle}
                                    onChange={(e) => setEmployeeData({ ...employeeData, JobTitle: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="label-and-input-pair">
                                <label>Payment Type:</label>
                                <select
                                    value={employeeData.PaymentType}
                                    onChange={(e) => setEmployeeData({ ...employeeData, PaymentType: e.target.value })}
                                >
                                    <option value="Salary">Salary</option>
                                    <option value="Hourly">Hourly</option>
                                </select>
                            </div>
                            <div className="label-and-input-pair">
                                {employeeData.PaymentType === 'Salary' ? (
                                    <>
                                        <label>Salary:</label>
                                        <input
                                            type="number"
                                            value={employeeData.Salary}
                                            onChange={(e) => setEmployeeData({ ...employeeData, Salary: e.target.value })}
                                            required
                                        />
                                    </>
                                ) : (
                                    <>
                                        <label>Hourly Rate:</label>
                                        <input
                                            type="number"
                                            value={employeeData.HourlyRate}
                                            onChange={(e) => setEmployeeData({ ...employeeData, HourlyRate: e.target.value })}
                                            required
                                        />
                                    </>
                                )}
                            </div>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmployeeDetailInfoCard;
