import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import './EmployeeDetailUsedLeaveCircle.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const EmployeeDetailUsedLeaveCircle = () => {
  // Mock data
  const [totalLeaveDays, setTotalLeaveDays] = useState(20);
  const leaveUsed = 10; // Changed to a constant value since it doesn't change
  const [newLimit, setNewLimit] = useState(totalLeaveDays);
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility

  // Data for the doughnut chart
  const data = {
    labels: ["Used Leave", "Remaining Leave"],
    datasets: [
      {
        data: [leaveUsed, totalLeaveDays - leaveUsed],
        backgroundColor: ["#ddd", "#4caf50"],
        hoverBackgroundColor: ["#e0e0e0", "#66bb6a"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "70%",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 20,
          padding: 15,
        },
      },
    },
    maintainAspectRatio: false,
  };

  // Function to handle setting the new leave limit
  const handleSetLimit = () => {
    const limit = parseInt(newLimit, 10);
    if (!isNaN(limit) && limit >= leaveUsed) {
      setTotalLeaveDays(limit);
      setModalOpen(false); // Close modal after setting the limit
    } else {
      alert("Please enter a valid limit greater than or equal to used leave.");
    }
  };

  return (
    <div className="employee-leave-container">
      <div className="chart-wrapper">
        <Doughnut data={data} options={options} />
      </div>
      <div className="text-wrapper">
        <h3>{leaveUsed}/{totalLeaveDays}</h3>
        <h5>Leave Balance</h5>
      </div>
      <button onClick={() => setModalOpen(true)}>Set Limit</button>

      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Set Leave Limit</h2>
            <span className="close-x" onClick={() => setModalOpen(false)}>
              <img src="/icons/x-icon.svg" alt="Close" />
            </span>
            <form onSubmit={(e) => {
              e.preventDefault(); // Prevent form submission
              handleSetLimit();
            }}>
              <div className="label-and-input-pair">
                <label htmlFor="leaveLimit">Leave Limit:</label>
                <input
                  type="number"
                  id="leaveLimit"
                  value={newLimit}
                  onChange={(e) => setNewLimit(e.target.value)}
                  placeholder="Set Leave Limit"
                  required
                />
              </div>
              <button type="submit">Confirm</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeDetailUsedLeaveCircle;
