import React from 'react';
import './OverviewUpcomingEvents.css';

// Mock data for holidays and birthdays
const mockEvents = [
  { id: 1, type: 'Holiday', name: 'New Year\'s Day', date: '2024-01-01' },
  { id: 2, type: 'Holiday', name: 'Independence Day', date: '2024-07-04' },
  { id: 3, type: 'Birthday', name: 'John\'s Birthday', date: '2024-10-09' },
  { id: 4, type: 'Holiday', name: 'Christmas', date: '2024-12-25' },
  { id: 5, type: 'Birthday', name: 'Anna\'s Birthday', date: '2024-10-22' },
];

// Utility function to format dates and determine if the event is in the past, today, or future
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const isFutureDate = (dateString) => {
  return new Date(dateString) > new Date();
};

const isToday = (dateString) => {
  const today = new Date();
  const eventDate = new Date(dateString);
  return (
    today.getDate() === eventDate.getDate() &&
    today.getMonth() === eventDate.getMonth() &&
    today.getFullYear() === eventDate.getFullYear()
  );
};

const sortEventsByDate = (events) => {
  return events.sort((a, b) => new Date(a.date) - new Date(b.date));
};

const OverviewUpcomingEvents = () => {

  const sortedEvents = sortEventsByDate(mockEvents);

  return (
    <div className="upcoming-events">
      <div className="card">
        <h2 className="title">Upcoming Events</h2>
        <ul className="event-list">
          {sortedEvents.map((event) => (
            <li key={event.id} className="event-item">
              <div className="event-details">
                <strong>{event.name}</strong>
                <span
                  className={`event-date ${
                    isToday(event.date) ? 'today' : isFutureDate(event.date) ? 'future' : 'past'
                  }`}
                >
                  {formatDate(event.date)}
                </span>
              </div>
              <span className={`event-type ${event.type.toLowerCase()}`}>
                {event.type}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OverviewUpcomingEvents;
