import React from 'react';
import DashboardNavbar from '../../components/DashboardNavbar/DashboardNavbar';
import OverviewEmployeeList from '../../components/OverviewEmployeeList/OverviewEmployeeList';
import OverviewUpcomingEvents from '../../components/OverviewUpcomingEvents/OverviewUpcomingEvents';
import './Overview.css';

const Dashboard = () => {
  return (
    <div className="overview">
      <DashboardNavbar />
      <div className='content'>
        <OverviewEmployeeList />  
        <OverviewUpcomingEvents />
      </div>
    </div>
  )
};

export default Dashboard;
